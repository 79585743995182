<template>
  <v-snackbar
    v-model="showAlert"
    bottom
    :timeout="alertTimeout"
    :color="alertType"
  >
    <div class="d-flex flex-column align-center">
      {{ alertMsg }}
      <v-btn text @click.prevent="showAlert = !showAlert">
        {{ labels.dismiss }}
      </v-btn>
    </div>
  </v-snackbar>
</template>

<script>
import { get } from 'lodash';
import { mapActions } from 'vuex';
import { labels } from '@/assets/texts.json';

export default {
  name: 'popupMessage',
  props: {
    alertMsg: {
      type: String,
      required: true,
    },
    alertType: {
      type: String,
      default: 'error',
      required: false,
    },
  },
  data: () => ({
    alertTimeout: 3000,
    labels,
  }),
  computed: {
    showAlert: {
      get() {
        return get(this, 'alertMsg.length', 0) > 0;
      },
      set() {
        this.resetAlertMsg();
      },
    },
  },
  methods: {
    ...mapActions({
      resetAlertMsg: 'shared/resetAlertMsg',
    }),
  },
};
</script>

<style scoped>
</style>
